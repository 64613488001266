<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="점검기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-select
            codeGroupCd="SOP_FIRE_FIGHTING_CHECK_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            label="점검유형"
            name="sopFireFightingCheckTypeCd"
            v-model="searchParam.sopFireFightingCheckTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-select
            stepperGrpCd="SOP_FIRE_FIGHTING_CHECK_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            label="LBLSTEPPROGRESS"
            name="sopFireFightingCheckStepCd"
            v-model="searchParam.sopFireFightingCheckStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-select
            :editable="editable"
            codeGroupCd="SOP_FIRE_FIGHTING_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="sopFireFightingTypeCd"
            label="설비유형"
            v-model="searchParam.sopFireFightingTypeCd"
            @datachange="setKind"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-select
            :editable="editable"
            :comboItems="kindItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="sopFireFightingKindCd"
            label="설비종류"
            v-model="searchParam.sopFireFightingKindCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-fire-fighting
            :editable="editable"
            type="search"
            label="소방설비"
            name="sopFireFightingId"
            v-model="searchParam.sopFireFightingId"
          ></c-fire-fighting>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="소방설비 점검 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingCheck',
  data() {
    return {
      searchParam: {
        plantCd: null,
        sopFireFightingCheckTypeCd: null,
        sopFireFightingCheckStepCd: null,
        period: [],
        sopFireFightingId: null,
        sopFireFightingTypeCd: null,
        sopFireFightingKindCd: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'checkName',
            field: 'checkName',
            label: '점검명',
            align: 'left',
            sortable: true,
            style: 'width: 250px',
            type: 'link'
          },
          {
            name: "sopFireFightingCheckTypeName",
            field: "sopFireFightingCheckTypeName",
            style: 'width:100px',
            label: "점검유형",
            align: "center",
            sortable: true,
          },
          {
            name: 'sopFireFightingCheckStepName',
            field: 'sopFireFightingCheckStepName',
            label: 'LBLPROGRESS',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: "checkPeriod",
            field: "checkPeriod",
            label: "점검기간",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "fireFightingName",
            field: "fireFightingName",
            label: "소방설비",
            style: 'width:350px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      kindItems: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.fireFightingCheck.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    setKind() {
      if (this.searchParam.sopFireFightingTypeCd) {
        this.$comm.getComboItems('SOP_FIRE_FIGHTING_KIND_CD', this.searchParam.sopFireFightingTypeCd).then(_result => {
          this.kindItems = _result;
          if (this.$_.findIndex(this.kindItems, { code: this.searchParam.sopFireFightingKindCd }) === -1) {
            this.$set(this.searchParam, 'sopFireFightingKindCd', null)
          }
        });
      } else {
        this.kindItems = [];
      }
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '소방설비 점검 상세';
      this.popupOptions.param = {
        sopFireFightingCheckId: row ? row.sopFireFightingCheckId : '',
      };
      this.popupOptions.target = () => import(`${'./fireFightingCheckDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
